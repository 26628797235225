import React, {useState} from 'react';
import {
    Box,
    Button,
    Flex, FormControl, FormLabel,
    Grid,
    Heading,
    HStack,
    Image, Input,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";
import {Form, Link as RouterLink, useNavigate} from "react-router-dom";
import axios from "axios";
import {TwitterFollowButton} from 'react-twitter-embed';

const RoundedBoxWithText = ({children, ...props}) => {
    return (
        <Box
            {...props}
            margin="30px"
            padding="20px"
            borderRadius="10px"
            backgroundColor="rgba(99,186,249)" //"#63BAFD"
            color="white"
            textAlign="center"
            boxShadow="-6px -10px 25px 15px #0076BA55" // last 2 numbers are opacity in hex
        >
            <Text fontSize="18pt" fontWeight="bold" textAlign="justify" flexWrap={1}>
                {children}
            </Text>
        </Box>
    );
};

const ScreenshotContent = () => {
    return (
        <Flex direction="column" align="center" alignItems="flex-start" minH="100vh" maxW="100vw">
            <Grid templateColumns="1fr auto 1fr" gap={0} mb={8} alignItems="flex-start" width="100%">
                <VStack spacing={4} alignContent="center">
                    <Heading fontSize="xl" mb={0} color="blue.300">Convert this description</Heading>
                    <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Image src="raw_screenshot_prompt.png" alt="Test prompt"
                               objectFit="contain"
                               width="80%"/>
                        {/*<RoundedBoxWithText alt="Test prompt"*/}
                        {/*                    width="50%">*/}
                        {/*    Navigate back to the conversations view. When on the conversations view take a screenshot.*/}
                        {/*</RoundedBoxWithText>*/}
                    </Box>
                </VStack>
                <Box width="5pt"/> {/* This box serves as the spacer */}
                <VStack spacing={4}>
                    <Heading fontSize="xl" mb="10pt" color="blue.300">To This Screenshot</Heading>
                    <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Image src="example_screenshot.png" alt="Test code" objectFit="scale-down" width="45%"/>
                    </Box>
                </VStack>
            </Grid>
            <HStack spacing={0} alignItems="center" justifyContent="center" width="100%" mt="10pt">
                <VStack alignItems="center" justifyContent="center" spacing={4} width="100%" mt="10pt">
                    <Heading fontSize="xl" mb={0} color="blue.300">In a matter of minutes !!!</Heading>
                    <Text>Signup for free and try generating screenshots for your own projects with the Beta</Text>
                    <HStack spacing={10}>
                        <Button as={RouterLink} to="/instructions?tab=0" colorScheme="blue">Watch Demo ▶</Button>
                        <Button as={RouterLink} to="/signup" colorScheme="blue">Signup (Free)</Button>
                    </HStack>
                </VStack>
            </HStack>
        </Flex>
    )
}

const UITestingContent = () => {
    return (
        <Flex direction="column" align="center" alignItems="flex-start" minH="100vh" maxW="50vw">
            <Grid templateColumns="auto" gap={8} mb={8} alignItems="flex-start" width="100%">
                <VStack spacing={4}>
                    <Heading fontSize="xl" mb={0} color="blue.300">Convert this test
                        description</Heading>
                    <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Image src="test_prompt.png" alt="Test prompt"
                               objectFit="contain"
                               width="70%"/>
                    </Box>
                </VStack>
                <Box width="40pt"></Box> {/* This box serves as the spacer */}
                <VStack spacing={4}>
                    <Heading fontSize="xl" mb="10pt" color="blue.300">To This Test</Heading>
                    <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Image src="test_code.png" alt="Test code" objectFit="contain" width="100%"
                               height="100%"/>
                    </Box>
                </VStack>
                <VStack spacing={4}>
                    <Heading fontSize="xl" mb={0} color="blue.300">In Seconds !!!</Heading>
                </VStack>
            </Grid>

            <HStack spacing={0} alignItems="center" justifyContent="center" width="100%" mt="10pt">
                <VStack alignItems="center" justifyContent="center" width="100%" mt="10pt">
                    <Button as={RouterLink} to="/signup" colorScheme="blue">Signup (Free)</Button>
                    <Text>Signup for free and try generating tests for your own projects with the Beta</Text>
                </VStack>
            </HStack>
        </Flex>
    )
}

const ComingSoonContent = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    async function signupToMailingList() {
        const data = {
            name: name,
            email: email
        };

        try {
            const response = await axios.post('/mailinglist/signup', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                console.log('Signup successful:', response.data);
                setSuccess(true)
            } else {
                console.log('Signup failed with status:', response.status);
                setSuccess(false)
            }
        } catch (error) {
            if (error.response.status === 409) {
                console.log('Conflict detected during signup, but on the list', error.response.status);
                setSuccess(true)
            } else {
                console.error('Error during signup:', error);
                setError(error)
            }
        }
    }

    return (
        <Flex direction="column" align="center" alignItems="flex-start" minH="100vh" maxW="50vw">
            <VStack spacing={10} alignItems="flex-start">
                <Box display="inline-block" bg="rgba(247, 248, 250, 0.2)" borderRadius="md" p={5}
                     alignItems="center"
                     justifyContent="center">
                    <VStack spacing={3} alignItems="flex-start">
                        <Heading size="md">Auto test generation via suggested test</Heading>
                        <Text>Ask our software to suggests UI tests to run on your app to increase stability and
                            reduce bugs. Then generate the code for those tests to integrate into your project,
                            saving massive amounts of time on having to write your own tests and potentially covering
                            behaviors you hadn't thought of</Text>
                    </VStack>
                </Box>
                <Box display="inline-block" bg="rgba(247, 248, 250, 0.2)" borderRadius="md" p={5} alignItems="center"
                     justifyContent="center">
                    <VStack spacing={3} alignItems="flex-start">
                        <Heading size="md">Video to UI tests</Heading>
                        <Text>Save massive amounts of time fixing bugs. Our software will take a video and generate a
                            UI test mimicking the behavior. This allows developers to save massive amounts of time
                            and quickly find bugs by running the test while debugging to find the root cause, then fix the
                            code to get the test to pass, resolving the bug</Text>
                    </VStack>
                </Box>
                <Text>Signup to our mailing list to learn more</Text>
                <Box display="inline-block" bg="rgba(247, 248, 250, 0.25)" borderRadius="md" p={5}>
                    <HStack spacing={4} alignItems="flex-end">
                        <FormControl isRequired>
                            <HStack>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    bg="gray.100"
                                    border="1px"
                                    borderColor="gray.300"
                                    _hover={{bg: "gray.200"}}
                                    disabled={success}
                                />
                            </HStack>
                        </FormControl>
                        <FormControl isRequired>
                            <HStack>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    bg="gray.100"
                                    border="1px"
                                    borderColor="gray.300"
                                    _hover={{bg: "gray.200"}}
                                    disabled={success}
                                />
                            </HStack>
                        </FormControl>
                        <Button type="submit" onClick={signupToMailingList} colorScheme="blue" isDisabled={success}
                                p={5}
                                px={10} ml={3}>{success ? "Added" : "Join List"}</Button>
                    </HStack>
                    {error && <FormLabel color="red">{error.message}</FormLabel>}
                </Box>
            </VStack>
        </Flex>
    )
}

const TabbedContent = () => {
    return (
        <Box width="100%" height="100%" alignItems="center" justifyContent="center" mt="10pt">
            <Tabs variant="soft-rounded" colorScheme="blue">
                <TabList justifyContent="center">
                    <Tab>Screenshots</Tab>
                    <Tab>UI Testing</Tab>
                    <Tab>Coming Soon</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Box width="100%" height="100%" display="flex" justifyContent="center" mt="10pt">
                            <ScreenshotContent/>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box width="100%" height="100%" display="flex" justifyContent="center" mt="10pt">
                            <UITestingContent/>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box width="100%" height="100%" display="flex" justifyContent="center" mt="10pt">
                            <ComingSoonContent/>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};


function HomePage() {

    const handleTryNow = () => {
        const navigate = useNavigate();
        console.log("Navigating to signup")
        navigate('/signup')
    };

    return (
        <VStack justifyContent="center" alignItems="center" width="70vw" height="100%" pb="10px">
            <Box display="inline-block" bg="rgba(247, 248, 250, 0.25)" borderRadius="md" p={5} alignItems="center"
                 justifyContent="center">
                <Heading fontSize="20pt" mb="10pt" flexWrap={1} width="100%" color="blue.300" textAlign="center">
                    Welcome to uitesting.tools !!!
                </Heading>
                <Heading fontSize="lg" flexWrap={1} width="100%" color="blue.300" textAlign="center">
                    Effortlessly create AppStore screenshots & UI tests for Apple platform apps with just a few
                    sentences in English using our AI. Simplify your testing process and boost productivity. <br/>We're
                    currently in Beta and looking for early users to give us feedback.
                </Heading>
                <Text textAlign="center" mt="10pt">Sign up to the Beta for free to generate tests for you own project
                    and help us shape our product by giving us feedback. Or try generating tests immediately for our
                    demo project</Text>
                <HStack alignitems="center" justifyContent="center" mt="10pt">
                    <Button as={RouterLink} to="/signup" colorScheme="blue" alignItems="center">Try the Beta (Free)
                        🛠</Button>
                    <Button as={RouterLink} to="/trytestgenerator" colorScheme="blue" alignItems="center">Try Now
                        (Free)</Button>
                </HStack>
                <HStack alignitems="center" justifyContent="center" mt="20pt">
                    <Heading fontSize="lg" flexWrap={1} color="blue.300">For the latest updates follow us on X</Heading>
                    <TwitterFollowButton options={{
                        size: 'large'
                    }}
                                         screenName={'uitesting_tools'}
                    />
                </HStack>
            </Box>
            <TabbedContent/>
        </VStack>

    )
        ;
}

export default HomePage;
